
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_towns(params)  {
            return await axios.post('towns/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_towns()  {
            // return await axios.get('towns/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_towns')
        },
        async delete_towns(town_id)  {
            return await axios.post('towns/delete.php' , {
                town_id : town_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(town_id)  {
            return await axios.post('towns/readOne.php' , {
                town_id : town_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_towns(params)  {
            return await axios.post('towns/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_towns(params)  {
            return await axios.post('towns/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
